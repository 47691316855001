import moment from 'moment-timezone';
import { cloneDeep, isDate, merge } from "lodash";

export function emptyStr(val) {
  if (typeof val == "undefined" || typeof val == "object" && val == null)
    return "";
  return val.toString();
}

export function valOrNull(val) {
  if (typeof val == "undefined" || val == null)
    return null;
  return val;
}

export function formatPhone(phone) {
  //console.log(phone);
  if (typeof phone != "string")
    phone = "";
  phone = phone.replace(/[^0-9]/g, "");
  if (phone == "")
    return "";
  return '(' + phone.substr(0, 2) + ') ' + phone.substr(2, phone.length - 6) + '-' + phone.substr(-4);
}

export function formatCpf(cpf) {
  //console.log(cpf);
  if (typeof cpf != "string")
    return "";

  cpf = cpf.replace(/[^0-9]/g, '');

  if (cpf.length != 11)
    return "";

  return cpf.substr(0, 3) + "." + cpf.substr(3, 3) + "." + cpf.substr(6, 3) + "-" + cpf.substr(9);
}

export function formatCpfCnpj(cpf) {
  if (typeof cpf != "string")
    return "";

  cpf = cpf.replace(/[^0-9]/g, '');

  if (cpf.length != 11 && cpf.length != 14)
    return "";

  if (cpf.length == 11)
    return cpf.substr(0, 3) + "." + cpf.substr(3, 3) + "." + cpf.substr(6, 3) + "-" + cpf.substr(9);

  return cpf.substr(0, 2) + "." + cpf.substr(2, 3) + "." + cpf.substr(5, 3) + "/" + cpf.substr(8, 4) + "-" + cpf.substr(12);
}

export function formatCurrency(val, prefix = "R$", getNumber = false) {
  if (typeof val == "string")
      val = parseFloat(val);
  else if (typeof val != "number" || isNaN(val)) {
      if (getNumber)
          return 0;
      return "";
  }

  if(getNumber)
      return parseFloat(val.toFixed(2));
  return ((prefix != "")?prefix + " ":"") + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)).replace("R$ ","");
}

export function formatDecimal(val) {
  if (typeof val == "string")
    val = parseFloat(val);
  else if (typeof val != "number")
    return null;

  val = new Intl.NumberFormat('pt-BR').format(val);

  if (val.indexOf(",") == -1) {
    val += ',00';
  } else if (val.indexOf(",") == (val.length - 2)) {
    val += '0';
  }

  return val;
}

export function strToDate(date, format = "br") {
  if (typeof date != "string" || date.trim() == "")
    return null;
  if(format == "br") {
    date = date.split("/").reverse().join("-");
  }
  return moment(date).tz('America/Sao_Paulo').toDate();
}

export function formatDate(date, format = "DD/MM/YYYY HH:mm:ss") {
  if (typeof date != "undefined" && date != null && date != "") {
      return moment(date, true).tz('America/Sao_Paulo').format(format);
  }
  return "";
}

export function formatDateTimeToBr(date) {
  if (typeof date != "string" || date == "")
    return null;

  date = date.split(" ");

  return date[0].split("-").reverse().join("/") + " " + date[1];
}

export function formatDateToBr(date) {
  if (typeof date != "string" || date == "")
    return null;
  return date.split("-").reverse().join("/");
}

export function formatDateToMysql(date) {
  if (typeof date != "string" || date == "")
    return null;
  return date.split("/").reverse().join("-");
}

export function getStdObject(obj, padrao = null, strict = false) {
  if (typeof padrao == "undefined")
    padrao = null;
  if (typeof strict != "boolean")
    strict = false;
  if (typeof obj == "undefined" || obj == null)
    return padrao;
  if (strict && typeof obj != "object")
    return padrao;
  return cloneDeep(obj);
}

export function daysFromNow(date) {

  if (!date)
    return -1;

  const end = moment(date, true).tz("America/Sao_Paulo");
  const now = moment().tz("America/Sao_Paulo");

  const duration = moment.duration(end.diff(now));

  return duration.asDays();

}

export function calcularDiferencaData(data1, data2) {
  // Converte as datas para Moment.js com o fuso horário de São Paulo
  //console.log(data1, data2);
  const momentData1 = moment.tz(data1, "America/Sao_Paulo");
  const momentData2 = moment.tz(data2, "America/Sao_Paulo");

  // Calcula a diferença total em minutos
  const diffInMinutes = Math.abs(momentData1.diff(momentData2, 'minutes'));

  // Converte minutos em dias, horas e minutos restantes
  const dias = Math.floor(diffInMinutes / 1440); // 1440 minutos em um dia
  const horas = Math.floor((diffInMinutes % 1440) / 60); // Minutos restantes convertidos para horas
  const minutos = diffInMinutes % 60; // Minutos restantes após calcular dias e horas

  // Monta o resultado detalhado
  let resultado = "";

  if (dias > 0) {
    resultado += `${dias} ${dias === 1 ? "dia" : "dias"}`;
  }
  if (horas > 0) {
    resultado += `${resultado ? ", " : ""}${horas} ${horas === 1 ? "hora" : "horas"}`;
  }
  if (minutos > 0 || (!dias && !horas)) {
    resultado += `${resultado ? " e " : ""}${minutos} ${minutos === 1 ? "minuto" : "minutos"}`;
  }

  return resultado;
}


export function inputNumberFocus(event) {
  event.target.setSelectionRange(0, event.target.value.length);
}

// #region Manipulação de Cookies //

export function setCookie(name, value, expire) {

  let dtExpire = validDate(expire)

  if(expire == null) {
    dtExpire = new Date();
    dtExpire.setTime(dtExpire.getTime() + (365 /*dias*/ * 24 * 60 * 60 * 1000));
  }

  dtExpire = "expires=" + dtExpire.toUTCString();
  
  document.cookie = name + "=" + JSON.stringify(value) + ";" + dtExpire + ";path=/;samesite=strict;partitioned";

}

export function getCookie(name) {

  if(validString(name) == "")
    return undefined;

  const cname = name + "=";

  const cookieValue = document.cookie
  .split("; ")
  .find((row) => row.startsWith(cname))
  ?.split("=")[1];

  return cookieValue?JSON.parse(cookieValue):undefined;

}

export function existsCookie(name) {

  if(validString(name) != "") {
    
    const cname = name + "=";

    return document.cookie.split(";").some((item) => item.trim().startsWith(cname));

  }

  return false;

}

// #endregion Manipulação de Cookies //

// #region Validações Booleanas (From: SDK) //

export function isMObject(val) {
  if (typeof val != "object")
    return false;

  if (val == null)
    return false;

  if (Object.keys(val).length == 0)
    return false;

  return true;
}

export function isCPF(strCPF) {
  strCPF = strCPF.replace(/[^0-9]/g, "");

  let i;
  let Soma = 0;
  let Resto;

  if (strCPF == "00000000000") return false;

  for (i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);

  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;

  if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);

  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;

  if (Resto != parseInt(strCPF.substring(10, 11))) return false;

  return true;
}

export function isCNPJ(cnpj) {
  let i;

  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado != digitos.charAt(1)) return false;

  return true;

}

export function isEmpty(val) {
  if (val === undefined || val === null)
    return true;

  switch (typeof val) {
    case "string": {
      return val.trim().length == 0;
    }
    case "object": {
      if(!isDate(val))
        return Object.keys(val).length == 0;
    }
  }

  return false;
}

export function isValidParams(params, fields, ignoreUndefined = false) {

  ignoreUndefined = validBoolean(ignoreUndefined);

  fields = validArray(fields);

  if (fields.length == 0)
    return [];

  params = validObject(params, {}, true);

  let erros = [];
  for (const f of fields) {
    const val = getPathOfObject(params, f);
    if (val === undefined && ignoreUndefined)
      continue;
    if (!isValid(val, true))
      erros.push(f);
  }

  return erros;

}

export function isValid(val, validEmpty = false) {
  // TODO Adequar para array ou objeto vazio e valores diferentes dos padrões, como números negativos e/ou positivos
  let valid = !(val === undefined || val === null || val === "" || val === 0);
  if (valid && validEmpty) {
    return isEmpty(val) == false;
  }
  return valid;
}

// #endregion Validações Booleanas (From: SDK) //

// #region Validações com Correção (From: SDK) //

export function validParams(params, fields, options = {}) {

  const options_defaults = {
    message: "Preencha corretamente todas as informações obrigatórias!",
    code: "FIELDS_ERROR",
    ignoreUndefined: false
  };

  options = { ...options_defaults, ...options };

  const erros = isValidParams(params, fields, options.ignoreUndefined);

  if (erros.length > 0) {

    throw {
      success: false,
      message: options.message,
      error: {
        code: options.code,
        message: options.message,
        fields: erros
      }
    };

  }

}

export function validString(val, def = "", notEmpty = false) {
  if (typeof def != "string")
    def = "";

  if (typeof val == "string") {
    if (notEmpty == false || val.trim() != "")
      return val;
  }

  return def;
}

export function validFloat(val, def = 0) {
  if (typeof def != "number")
    def = 0;

  if (typeof val == "number")
    return parseFloat(val.toFixed(2));

  return def;
}

export function validInteger(val, def = 0) {
  if (typeof def != "number")
    def = 0;

  if (typeof val == "number")
    return parseInt(val.toString());

  return def;
}

export function validBoolean(val, def = false) {
  if (typeof def != "boolean")
    def = false;

  if (typeof val == "boolean")
    return val;

  return def;
}

export function validObject(val, def = {}, notNull = true) {
  if (typeof def != "object")
    def = null;

  if (typeof val == "object")
    if (notNull == false || val != null)
      return val;

  return def;
}

export function validArray(val, def = []) {
  if (typeof def != "object" || !Array.isArray(def))
    def = [];

  if (typeof val == "object" && val != null) {

    if (Array.isArray(val)) {
      return val;
    }

    const k = Object.keys(val);
    let i;
    for (i = 0; i < k.length && k[i] == i.toString(); i++);
    if (i == k.length)
      return Object.values(val);

  }

  return def;
}

export function validDate(val, def = null, strict = false) {
  if (strict && typeof def != "object")
    def = null;

  if (typeof val == "undefined" || val == null)
    return def;

  if (typeof val == "object" && val instanceof Date)
    return val;

  if (typeof val == "string" && /[0-2][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9].*/.test(val))
    return new Date(val);

  return def;
}

// #endregion Validações com Correção (From: SDK) //

// #region Manipulação de Objetos (From: SDK) //

export function arrObjDeepUpdate(arr, funcao) {
  for (let i = 0; i < arr.length; i++) {
    arr[i] = objDeepUpdate(arr[i], funcao);
  }
  return arr;
}

export function objDeepUpdate(obj, funcao) {
  if (typeof obj != "object" || obj == null)
    return obj;

  for (const [k, v] of Object.entries(obj)) {
    if (k == "id") continue;
    if (typeof v == "object") {
      if (v instanceof Date)
        continue;
      obj[k] = objDeepUpdate(v, funcao);
    } else
      obj[k] = funcao(v);
  }
  return obj;
}

export function setPathOfObject(obj, path, value) {

  if (typeof path != "string" || path == "")
    return;

  const formatoresRegx = /(\[[^\]]*\])|(\{[^}]*\})/g
  const dadosFormatadores = path.match(formatoresRegx) || [];

  let _path = path.replace(formatoresRegx, "#").split(".");

  if (_path.length == 0)
    return;

  const pArr = [];
  let iDF = 0;
  for (const [i, p] of Object.entries(_path)) {
    const ii = parseInt(i);
    if (p === "#") {
      _path[ii] = dadosFormatadores[iDF++];
      if (_path[ii].startsWith("[")) {
        pArr.push(ii - 1);
        _path[ii] = _path[ii].replace("[", "").replace("]", "");
      } else {
        _path[ii] = _path[ii].replace("{", "").replace("}", "");
      }
    }
  }

  let pObj = Object();
  let nObj = pObj;
  for (let [i, p] of Object.entries(_path)) {

    const ii = parseInt(i)

    if (ii == (_path.length - 1)) {
      pObj[p] = value;
    } else {

      if (pObj[p] === undefined) {
        if (pArr.includes(ii))
          pObj = pObj[p] = [];
        else
          pObj = pObj[p] = Object();
      }

    }
  }

  merge(obj, nObj);

}

export function unsetPathOfObject(obj, path) {

  if (typeof path != "string" || path == "")
    return false;

  const _path = path.split(".");

  if (_path.length == 0)
    return false;

  const last = _path.pop();

  for (const p of _path) {
    if (typeof obj == "object")
      obj = obj[p];
    else {
      obj = null;
      break;
    }
  }

  if (obj != null && typeof last != 'undefined') {

    if (typeof obj[last] == "undefined") {
      return false;
    }

    if (Array.isArray(obj)) {
      obj.splice(parseInt(last), 1);
    } else {
      delete obj[last];
    }

    return true;

  }

  return false;

}

export function getPathOfObject(obj, path) {

  if (typeof path != "string" || path == "")
    return undefined;

  const _path = path.split(".");

  for (const p of _path) {
    if (typeof obj == "object" && obj != null)
      obj = obj[p];
    else {
      obj = undefined;
      break;
    }
  }

  return obj;

}

// #endregion Manipulação de Objetos (From: SDK) //
