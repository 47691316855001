<template>
  <Toast position="top-right" />
  <ConfirmDialog></ConfirmDialog>
  <div v-if="withLayout" class="layout-wrapper">
    <AppTopBar />

    <div class="layout-main md:px-3 pb-5">
      <div class="ajuste-altura">
        <router-view />
      </div>
    </div>

    <AppFooter />
  </div>
  <div v-else><router-view /></div>
</template>

<script>
import AppTopBar from "./componentes/AppTopbar.vue";
import AppFooter from "./componentes/AppFooter.vue";

import { createGtm } from '@gtm-support/vue-gtm';
import moment from "moment-timezone";

import "./assets/layout/App.scss";

export default {

  data() {
    return {
      withLayout: true,
      corApp: '',

      //USADO GLOBALMENTE
      version: "2.0.0",
      carrinho: null,
      carrinhoQtd: 0,
      pedidos:[],
      pedidosQnt:0,
      gps: null,

      conteudo: [],

      timerFuncionamento: null,
      timerPedidos: null,

    };
  },

  // OK
  mounted() {
    
    if(this.$utils.isMobileOrTablet())
      document.body.classList.add('is-touch');

    if(/iPhone/i.test(navigator.userAgent)) {
      document.body.classList.add('is-iphone');
    }

    navigator.geolocation.getCurrentPosition( (pos) => {
      this.gps = {
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude
      };
    }, (err) => {
      console.error(err);
    }, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    });

    this.carregaCarrinho();

  },

  watch: {

    // OK
    $route() {
      this.withLayout = !this.$route.matched.some(
        (record) => record.meta.freeLayout
      );
      this.$toast.removeAllGroups();
    },

    // OK
    '$auth.usuario': {
      handler: function() {
        
        if(this.$auth.existsUsuario()) {
          this.carregaCarrinho();
          this.carregaPedidos();
        }
      },
      deep: true,
      immediate: true
    },

    // OK
    '$auth.empresa': {
      handler: async function() {
        clearInterval(this.timerFuncionamento);
        if(this.$auth.existsEmpresa()) {
          this.corApp = this.$auth.empresa.config?.cabecalho?.color ?? "";
          this.initGTM();
    
          if(this.$auth.empresa.funcionamentoInicio != "00:00" || this.$auth.empresa.funcionamentoFim != "23:59") {
            this.timerFuncionamento = setInterval(this.timerFnFuncionamento, 1000);
          }

        } else {
          this.corApp = '';
        }
      },
      deep: true,
      immediate: true
    },

    // OK
    corApp: function (cor) {
      if(cor) {
        document.body.style = '--primaryBackgroundColor: #' + cor + '; --secondaryColor: #' + cor;
      } else {
        document.body.style = '';
      }
    }

  },

  methods: {

    // OK
    initGTM() {

      if(typeof this.$auth.empresa.gtm == "string" && this.$auth.empresa.gtm != "") {

        this.$.appContext.app.use(
          createGtm({
            id: this.$auth.empresa.gtm, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
            enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
            debug: false, // Whether or not display console logs debugs (optional)
            loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
            vueRouter: this.$router, // Pass the router instance to automatically sync with router (optional)
            // trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
          }),
        );

      }

    },

    // OK
    timerFnFuncionamento() {

      const agora = moment().tz("America/Sao_Paulo");
      const inicio = moment(agora.format("YYYY-MM-DD") + " " + this.$auth.empresa.funcionamentoInicio, "YYYY-MM-DD HH:mm", true).tz("America/Sao_Paulo");
      const fim = moment(agora.format("YYYY-MM-DD") + " " + this.$auth.empresa.funcionamentoFim, "YYYY-MM-DD HH:mm", true).tz("America/Sao_Paulo");

      if(agora < inicio || agora > fim) {
        alert("ATENÇÃO: Empresa fechada! O horário de funcionamento é de " + this.$auth.empresa.funcionamentoInicio + " às " + this.$auth.empresa.funcionamentoFim + ".");
        clearInterval(this.timerFuncionamento);
      } else if(agora > fim.subtract(10, "minutes")) {
        alert("ATENÇÃO: O horário de funcionamento da empresa é de " + this.$auth.empresa.funcionamentoInicio + " às " + this.$auth.empresa.funcionamentoFim + ".");
        clearInterval(this.timerFuncionamento);
      }

    },

    // OK
    isDesktop() {
      return window.innerWidth > 1024;
    },

    // OK
    async carregaCarrinho() {

      // if(!this.$auth.existsUsuario()) {
      //   this.carrinho = null;
      //   this.carrinhoQtd = 0;
      //   return;
      // }
      
      this.carrinho = null;
      this.carrinhoQtd = 0;
      // const url = "Pedido.update."+(this.$auth.usuario?.id ? this.$auth.usuario.id : this.$fpid)
      // console.log(url )
      // this.$rt.on(url, (dados) => { 
      //   //console.log(dados)
      //   this.carrinho = dados.data.data;
      //   this.carrinhoQtd = Object.keys(this.carrinho.listaProdutos).length;
      //  });
      const response = await this.$api.get("/pedidos/" + (this.$auth.usuario?.id ? this.$auth.usuario.id : this.$fpid));
      //console.log(response.data)
      if(response.data.success) {
        this.carrinho = response.data.data;
        this.carrinhoQtd = Object.keys(this.carrinho.listaProdutos).length;
      }

    },

    // OK
    async carregaPedidos(){

      if(!this.$auth.existsUsuario()) {
        this.pedidos = null;
        this.pedidosQnt = 0;
        return;
      }

      const response = await this.$api.get("/pedidos?usuario.id=" + (this.$auth.usuario?.id ? this.$auth.usuario.id : this.$fpid));
      //console.log(response.data)
     
      if(response.data.success) {
        this.pedidos = response.data.data;
        this.pedidosQnt = this.pedidos.length;

        if(this.pedidos.length > 0)
          setTimeout(this.carregaPedidos, 5000);

      } else {
        this.pedidos = null;
        this.pedidosQnt = 0;
      }

    },

    // OK
    async carregaConteudo() {
      //console.log("começou")
      let res = await this.$api.get("/lojas-home/" + this.$auth.loja.id);
      //console.log(res.data)
      res = res.data;
      
      if (res.success) {
        this.conteudo = res.data;
      } else {
        this.conteudo = [];
      }

    }

  },

  components: {
    'AppTopBar': AppTopBar,
    'AppFooter': AppFooter,
  },

};
</script>
