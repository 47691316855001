import { defineComponent } from 'vue'

export default defineComponent({

    props: {
        modelValue: {
            type: Object
        },
        apiKey: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: 'Digite um endereço'
        }
    },

    emits: [
        'update:modelValue'
    ],

    data() {
        return {
            autocomplete: null
        }
    },

    mounted() {
        // TODO INICIAR PREENCHIDO COM OS DADOS QUE FORAM GRAVADOS NO BANCO
        this.loadGooglePlacesScript();
    },

    unmounted() {
        this.cleanup();
    },

    methods: {

        // Carrega o script do Google Places API
        loadGooglePlacesScript() {
            const script = document.createElement('script')
            script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`
            script.async = true
            script.defer = true
            script.id = 'google-places-script'

            script.onload = this.initializeAutocomplete
            document.head.appendChild(script)
        },

        // Inicializa o autocomplete
        initializeAutocomplete() {
            if (!this.$refs.addressInput) return

            this.autocomplete = new google.maps.places.Autocomplete(this.$refs.addressInput, {
                types: ['address'],
                fields: ["address_components", "geometry"],
                componentRestrictions: { country: 'BR' } // Restringe para endereços do Brasil
            })

            // Adiciona listener para quando um lugar é selecionado
            this.autocomplete.addListener('place_changed', () => {

                const place = this.autocomplete.getPlace()

                const local = {
                    logradouro: ['route'],
                    numero: ['street_number'],
                    bairro: ['sublocality_level_1', 'sublocality', 'political'],
                    cidade: ['administrative_area_level_2', 'political'],
                    estado: ['administrative_area_level_1', 'political'],
                    pais: ['country', 'political'],
                    cep: ['postal_code'],
                };

                for(const [k,o] of Object.entries(local)) {

                    for(const ko of o) {

                        const v = place.address_components.find( c => c.types.includes(ko) );

                        if(v) {
                            local[k] = {
                                text: v.long_name,
                                short: v.short_name
                            };
                            break;
                        }

                    }

                }

                for(const [k,v] of Object.entries(local)) {
                    if(Array.isArray(v)) {
                        local[k] = {
                            text: "",
                            short: ""
                        };
                    }
                }

                if (place.geometry) {
                    local.lat = place.geometry.location.lat();
                    local.lng = place.geometry.location.lng();
                } else {
                    local.lat = local.lng = 0;
                }

                local.endereco = this.$refs.addressInput.value;

                this.$emit('update:modelValue', local);

            })
        },

        // Limpa recursos quando o componente é destruído
        cleanup() {
            const script = document.getElementById('google-places-script')
            if (script) {
                script.remove()
            }

            if (this.autocomplete) {
                google.maps.event.clearInstanceListeners(this.autocomplete)
            }
        }

    }

});