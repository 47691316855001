<template>

    <div class="google-places-autocomplete w-full">
        <input
            ref="addressInput"
            type="text"
            :placeholder="placeholder"
            class="p-inputtext p-component w-full"
        />
    </div>

</template>

<script src="./GooglePlacesAutocomplete.js"></script>

<style>
    .pac-container {
        z-index: 99000 !important;
    }
</style>