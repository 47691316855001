import axios from 'axios';
// import { getAuth } from "firebase/auth";

export default class {

    constructor(api) {
        this.urlApi = api;
        this.token = null;
    }

    tokenToRequest(token) {
        this.token = token;
        return this;
    }

    async headers() {

        let token;

        if(this.token != null) {
            token = Buffer.from(this.token).toString('base64');
            this.token = null;
        } else {
            // const currentUser = getAuth().currentUser;
            // token = Buffer.from(currentUser.uid).toString('base64');
            token = "INVALID";
        }

        return { 
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
        };
        
    }

    async get(endpoint/*, data = null*/) {

        if(typeof endpoint != "string")
            return null;

        //TRATAR PARAMS typeof data != object || data == null

        return axios({
            method: 'get',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
        });

    }

    async post(endpoint, data = null) {

        if(typeof endpoint != "string" || typeof data != "object" || data == null)
            return null;

        return axios({
            method: 'post',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
            "data" : data
        });

    }

}