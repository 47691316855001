import { emptyStr, validObject } from '@/support/utils';
import axios from 'axios';
import Auth from './auth';

export default class API {

    urlApi;
    #anonymousId = "";

    constructor(api) {
        this.urlApi = api;
    }

    setAnonymousId(id = "") {
        this.#anonymousId = emptyStr(id);
    }

    async headers() {

        while(!this.#anonymousId) {
            await new Promise((resolve) => setTimeout(resolve, 500));
        }

        const gToken = Auth.getToken();

        const headers = {
            'Accept': 'application/json',
            'idEmpresa': gToken.idEmpresa,
            'idLoja': gToken.idLoja
        };

        if(gToken.token != "") {
            headers.Authorization = 'Bearer ' + gToken.token;
        } else {
            headers.Authorization = 'Anonymous ' + this.#anonymousId;
        }

        return headers;

    }

    async get(endpoint, data = {}) {

        if(typeof endpoint != "string")
            return null;

        data = validObject(data, {}, true);

        for(const [k,v] of Object.entries(data)) {
            if(Array.isArray(v))
                data[k] = v.join("|");
            else if(typeof v != "string") {
                delete data[k];
            }
        }

        let query = (new URLSearchParams(data)).toString();
        if(query != "")
            query = "?" + query;

        return axios({
            method: 'get',
            url: this.urlApi + endpoint + query,
            withCredentials: true,
            headers: await this.headers(),
            data: null
        });

    }

    async post(endpoint, data = null) {

        if(typeof endpoint != "string")
            return null;

        if(typeof data != "object" || data == null)
            data = {};

        return axios({
            method: 'post',
            url: this.urlApi + endpoint,
            withCredentials: true,
            headers: await this.headers(),
            "data" : data
        });

    }

    async put(endpoint, data = null) {

        if(typeof endpoint != "string" || typeof data != "object" || data == null)
            return null;

        if(typeof data != "object" || data == null)
            data = {};

        return axios({
            method: 'put',
            url: this.urlApi + endpoint,
            withCredentials: true,
            headers: await this.headers(),
            "data" : data
        });

    }

    async delete(endpoint, data = null) {

        if(typeof endpoint != "string")
            return null;

        if(typeof data != "object" || data == null)
            data = {};

        return axios({
            method: 'delete',
            url: this.urlApi + endpoint,
            withCredentials: true,
            headers: await this.headers(),
            "data" : data
        });

    }

}