<template>
  <div class="loading" v-show="carregando">
    <div class="center-spinner" v-show="carregando">
      <ProgressSpinner />
    </div>
  </div>
  <div class="grid p-fluid" style="margin: -1rem -1rem 0 -1rem">
    <GoogleMap ref="mapa" api-key="AIzaSyC-mah_TjzO0fSC1X5AgUgj02oSKLU-tZc" style="width: 100%; height: 300px"
      :center="mapCenter" :zoom="14" v-if="pedido.ma">
      <Marker v-for="(m, index) in mapMarkers" :key="index" :options="m" />
    </GoogleMap>
    <div class="col-12 mt-2 titulo-1">Previsão de entrega</div>
    <div class="col-12" style="margin-top: -10px">Não calculada</div>
    <hr style="width: 96%" />
    <div class="col-4 titulo-1">Status: {{ pedido.status }}</div>
    <div class="col-8 mt-1">
      <ProgressBar mode="indeterminate" style="height: 5px" />
    </div>
    <div class="circle" style="--quadrado: 44px" v-if="motorista.imagem">
      <img :src="motorista.imagem" alt="imageIcon" />
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style="enable-background: new -580 439 577.9 194"
        xml:space="preserve">
        <circle cx="50" cy="50" r="40" />
      </svg>
    </div>
    <div class="col-8 titulo-1 mt-2" v-if="motorista.nome">{{ motorista.nome }} está realizando sua entrega.</div>
    
    <hr style="width: 96%" />

    <div class="col-12" v-show="retirar">Retirar em:</div>
    <div class="col-12 titulo-1" style="margin-top: -10px" v-show="retirar">{{ endereco }}</div>

    <div class="col-12 titulo-1 text-center underline" style="margin-top: -10px; font-weight: 600" v-show="horarioFuncionamento != ''">Horário de Funcionamento: {{ horarioFuncionamento }}</div>

    <div class="col-12 codigo" v-if="codigo" v-show="retirar">O Código <span>{{ codigo }}</span> deverá ser informado para
      retirar seu pedido.</div>

    <div class="col-12" v-show="!retirar">Entrega em:</div>
    <div class="col-12 titulo-1" style="margin-top: -10px" v-show="!retirar">{{ endereco }}</div>
    <div class="col-12 codigo" v-if="codigo" v-show="!retirar">O Código <span>{{ codigo }}</span> deverá ser informado ao entregador para
      receber seu pedido.</div>

    <hr style="width: 96%" />

    <div class="col-12">Detalhe do pedido:</div>
    <div class="circle" style="--quadrado: 44px">
      <img :src="pedido.loja.imagem" alt="imageIcon" />
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style="enable-background: new -580 439 577.9 194"
        xml:space="preserve">
        <circle cx="50" cy="50" r="40" />
      </svg>
    </div>
    <div class="col-9 mt-1">
      <div>{{ pedido.loja.nome }}</div>
      <div class="titulo-1 mt-2" style="color: var(--primaryBackgroundColor)">

      </div>
    </div>
    <div class="col-6">Pagamento: {{ (pedido.pagamentoEfetivado) ? ( (typeof pedido.pagamentoConfirmado == "boolean" && pedido.pagamentoConfirmado == false)? "Presencial" : "Confirmado") : "Aguardando" }}</div>
    <div class="col-6 text-right">Tipo: {{ pedido.tipoPagamento }}</div>
    <div class="col-6 text-left">Entrega</div>
    <div class="col-6 text-right">
      {{ $utils.formatCurrency(pedido.valorEntrega) }}
    </div>
    <div class="col-6 text-left">Produtos</div>
    <div class="col-6 text-right">
      {{ $utils.formatCurrency(pedido.valorVenda) }}
    </div>
    <div class="col-6 text-left">Total</div>
    <div class="col-6 text-right">
      {{ $utils.formatCurrency(pedido.valorTotal) }}
    </div>
  </div>
  
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";
// import { getDatabase, ref, onValue } from "firebase/database";

export default {
  name: "AdicionarCarrinho",
  props: ["value"],
  data() {
    return {
      // Controle de apresentação
      carregando: true,
      informacoesValidadas: false,
      retirar: false,

      // Dados
      quantidade: 1,
      tamanho: null,
      cor: null,
      valor: 0,
      value2: "",
      horarioFuncionamento: "",
      //Chaves estrangeiras
      tamanhos: [],
      cores: [],
      pedido: { loja: { foto: "" } },
      codigo: "",
      endereco: "",
      totalProduto: 0,

      map: null,
      mapCenter: { lat: -16.713681718591975, lng: -49.26569591634313 },
      mapMarkers: {},
      motorista: {},
    };
  },
  emits: ["add"],
  mounted() {
    
    this.pedido = this.value;
    this.endereco = this.$auth.usuario.registro.enderecoResumido
    const telefone = Array.isArray(this.$auth.usuario.registro.contatos) && this.$auth.usuario.registro.contatos.length > 0 ? this.$utils.formatPhone(this.$auth.usuario.registro.contatos[0].telefone):"";
    this.codigo = telefone.replace(/[^0-9]/, "").substr(-4);
    this.carregarCorrida();

    this.retirar = false;
    let tipoE = this.pedido.tipoEntrega;
    if (tipoE?.id) {
      tipoE = tipoE.id.split("-");

      if(tipoE[0] == "retirar") {
        this.retirar = true;
        if(tipoE.length == 1) {
          this.endereco = this.$auth.empresa.endereco;
        } else {
          if(typeof this.$auth.empresa.distribuidores == "object" && typeof this.$auth.empresa.distribuidores[tipoE[1]] == "object")
            this.endereco = this.$auth.empresa.distribuidores[tipoE[1]].endereco;
          else
            this.endereco = "Endereço indisponível";
        }
      }

    }

    this.carregando = false;
    //this.centralizar_mapa();
  },

  watch: {
    "$auth.loja": {
      handler: async function (empresa) {

        if(this.$auth.existsEmpresa()) {
          if(empresa.cfgEcommerce.funcionamentoInicio == "00:00" && empresa.cfgEcommerce.funcionamentoFim == "23:59")
            this.horarioFuncionamento = "24 horas";
          else
            this.horarioFuncionamento = "de " + empresa.cfgEcommerce.funcionamentoInicio + " às " + empresa.cfgEcommerce.funcionamentoFim;
        } else {
          this.horarioFuncionamento = "";
        }

      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async carregarCorrida() {
      //console.log("/pedidos/"+this.pedido.entrega.corrida)
      const ret = await this.$api.get("/corridas/"+this.pedido.entrega.corrida);
      console.log(ret.data)
      if(ret.data.success){
        if(this.$utils.isMObject(ret.data.data.entregador)){
          this.getMotorista(ret.data.data.entregador)
        }
      }
    },
    async getMotorista(entregador) {
      // console.log("Entregador",entregador.nome)
      if (!this.$utils.isMObject(entregador))return;

      const ret = await this.$api.get("/usuarios/"+entregador.id);
      
      if(!ret.data.success)return;

      this.motorista = ret.data.data;
      //this.acompanhar_entrega(this.pedido)
      
    },
    clearMap() {
      if (this.loja == null) return;

      this.mapCenter = {
        lat: this.loja.latitude,
        lng: this.loja.longitude,
      };

      this.mapMarkers = {
        loja: {
          title: this.loja.nome,
          position: this.mapCenter,
          icon: "/icos/origemcorrida.png",
        },
      };
    },
    acompanhar_entrega(pedido) {
      // const self = this;

      // if (refEntregador != null) {
      //   refEntregador.off();
      //   refEntregador = null;
      // }

      if (typeof this.mapMarkers.destino != "undefined")
        delete this.mapMarkers.destino;

      if (typeof this.mapMarkers.entregador != "undefined")
        delete this.mapMarkers.entregador;

      this.mapMarkers.destino = {
        title: pedido.usuario.nome,
        position: {
          lat: pedido.usuario.latitude,
          lng: pedido.usuario.longitude,
        },
        icon: "/icos/destinocorrida.png",
      };

      if (this.$utils.isMObject(this.motorista)) {
        this.mapMarkers.entregador = {
          title: this.motorista.nome,
          position: {
            lat: this.motorista.lat,
            lng: this.motorista.lng,
          },
          icon: "/icos/iconemegawebmotoqueiro.png",
        };

        const self = this;
        this.$rt.on("Usuario.update." + this.motorista.id, (dados) => {
            
            const pos = dados.data.data;
            let entregador = self.$utils.getStdObject(self.mapMarkers.entregador);
            
            entregador.position = {
              lat: pos.lat,
              lng: pos.lng,
            };
            //console.log(entregador)
            self.mapMarkers.entregador = entregador;
            
            self.centralizar_mapa();
          });

        // refEntregador = firebase
        //   .database()
        //   .ref(
        //     "Empresas/" +
        //     this.loja.logistica +
        //     "/LocalMotoboy/" +
        //     pedido.statusEntrega.entregador.id +
        //     "/l"
        //   );

        
        // refEntregador.on("value", function (snapshot) {
        //   const pos = snapshot.val();

        //   let entregador = self.$utils.getStdObject(self.mapMarkers.entregador);

        //   entregador.position = {
        //     lat: pos[0],
        //     lng: pos[1],
        //   };

        //   self.mapMarkers.entregador = entregador;

        //   self.centralizar_mapa();
        // });
        
      }
    },
    centralizar_mapa() {
      
      let bounds = new this.$refs.mapa.api.LatLngBounds();
      for (const m of Object.values(this.mapMarkers)) {
        bounds.extend(m.position);
      }
      console.log(bounds)
      this.$refs.mapa.map.fitBounds(bounds);
      this.$refs.mapa.map.panToBounds(bounds);
    },
  },
  components: {
    GoogleMap,
    Marker,
  },
};
</script>

<style lang="scss">
.circle {
  position: relative;
  width: 60px;
  height: 60px;

  svg {
    fill: none;
    stroke: var(--primaryBackgroundColor, $primaryBackgroundColor);
    stroke-linecap: round;
    stroke-width: 3;
    stroke-dasharray: 1;
    stroke-dashoffset: 0;
    animation: stroke-draw 6s ease-out infinite alternate;
    // animation: stroke-draw 6s cubic-bezier(0.77, 0, 0.175, 1) infinite alternate;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    border-radius: 50%;
  }
}

.img-bord-branco {
  border-width: 3px;
  border-color: var(--primaryBackgroundColor, $primaryBackgroundColor);
  border-style: solid;
}

.adicionar-carrinho-carregando {
  padding: 40px;
}

.adicionar-carrinho-cores,
.adicionar-carrinho-tamanhos {
  max-width: 445px;
}

.adicionar-carrinho-cor,
.adicionar-carrinho-tamanho {
  padding: 0.5rem;
  cursor: pointer;
}

.adicionar-carrinho-cor-image {
  width: 45px;
  margin: 0 auto;
}

.adicionar-carrinho-tamanho span {
  background: #dddddd;
  border-radius: 4px;
}

.adicionar-carrinho-tamanho.selecionado span {
  background: var(--primaryBackgroundColor, $primaryBackgroundColor);
  color: white;
}

.adicionar-carrinho-cor.selecionada .adicionar-carrinho-cor-image {
  border: 3px solid var(--primaryBackgroundColor, $primaryBackgroundColor);
}

.adicionar-carrinho-quantidade-button {
  background-color: var(--primaryBackgroundColor,
      $primaryBackgroundColor ) !important;
  border-color: var(--primaryBackgroundColor,
      $primaryBackgroundColor ) !important;
}
</style>

<style lang="scss" scoped>
.codigo {
  text-align: center;
  font-size: 14px;
  margin: 0 auto;
  width: 80%;
}

.codigo span {
  background: #ddd;
  padding: 1px 4px;
  font-weight: bold;
  letter-spacing: 2px;
}
</style>