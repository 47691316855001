<template>
  <div class="layout-topbar fixed top-0 right-0 left-0 px-1 md:px-5 pt-1">
    <div class="grid">
      <div class="col relative">
        <span class="absolute pi pi-search text-xl z-1"></span>
        <AutoComplete
          placeholder="O que deseja encontrar?"
          v-model="buscaValor"
          :suggestions="buscaFiltrada"
          @complete="buscar($event)"
          field="nomeApresentacao"
          inputClass="w-full pl-5"
          class="w-full"
          @item-select="click"
        />
      </div>

      <div
        class="layout-profile-buttons col-fixed text-right"
        style="width: 195px"
      >
        <button
          class="
            layout-like-button layout-top-button
            border-circle border-none
            w-2rem
            h-2rem
            mr-3
          "
        >
          <span class="pi pi-heart text-xl"></span>
        </button>
        <Button
          icon="pi pi-shopping-cart text-xl"
          class="
            layout-cart-button layout-top-button
            border-circle border-none
            w-2rem
            h-2rem
            mr-3
            overflow-visible
          "
          @click="$router.push('/carrinho')"
          v-badge.danger="carrinhoQtd"
        >
        </Button>
         <Button
          icon="pi pi-book text-xl"
          class="
            layout-cart-button layout-top-button
            border-circle border-none
            w-2rem
            h-2rem
            mr-3
            overflow-visible
          "
          @click="$router.push('/listapedidos')"
          v-badge.danger="pedidosQnt"
        >
        </Button>
        <Button
          icon="pi pi-user text-xl"
          class="
            layout-profile-button layout-top-button
            border-circle border-none
            w-2rem
            h-2rem
          "
          @click="displayCadastro = true"
        >
        </Button>
      </div>
    </div>
  </div>

  <Dialog
    :position="$utils.isMobileCheck() ? 'bottom' : 'center'"
    v-model:visible="displayCadastro"
    style="width: 530px; max-width: 98%"
    :modal="true"
    :closable="true"
  >
    <template #header>
      <h5 class="m-0 text-xl font-medium">
        <i class="pi pi-user" style="font-size: 1.3rem"></i> Autenticação
      </h5>
    </template>
    <CadastroCliente
      @deslogado="displayCadastro = false"
      @logado="displayCadastro = false"
    />
  </Dialog>
</template>

<script>
import CadastroCliente from "./CadastroCliente.vue";

export default {
  data() {
    return {
      displayCadastro: false,

      buscaValor: null,
      buscaFiltrada: null,
      empresa: {},
      carrinhoQtd: 0,
      pedidosQnt:0
    };
  },
  mounted() {
    document.body.classList.add("with-topbar");
  },
  watch: {
    "$root.carrinhoQtd": {
      handler: function (qtd) {
        this.carrinhoQtd = qtd;
      },
      deep: true,
      immediate: true,
    },
    "$root.pedidosQnt": {
      handler: function (qtd) {
        this.pedidosQnt = qtd;
      },
      deep: true,
      immediate: true,
    },
    "$auth.empresa": {
      handler: function (empresa) {
        if (this.$auth.existsEmpresa()) {
          this.empresa = empresa;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {

    buscaInterna: async function(q) {

      if(this.$root.conteudo.length == 0)
        return false;

      let resultados = [];

      q = q.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

      for(const c of this.$root.conteudo) {

        if(typeof c.nome == "undefined")
          c.nome = "";

        const categoria = c.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();

        if(categoria.indexOf(q) != -1) {
          resultados.push({
            id: c.id,
            tipo: "categoria",
            nomeApresentacao: c.nome + " (categoria)",
            nomeLimpo: categoria
          });
        }

        for(const i of c.itens) {

          console.log(i.nome);

          if(typeof i.nome == "undefined")
            i.nome = "";
          const nome = i.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();

          console.log(nome);

          if(typeof i.descricao == "undefined")
            i.descricao = "";
          const descricao = i.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();

          if(nome.indexOf(q) != -1 || descricao.indexOf(q) != -1) {
            resultados.push({
              id: i.id,
              tipo: "produto",
              nomeApresentacao: i.nome,
              nomeLimpo: nome
            });
          }

        }

      }

      this.buscaFiltrada = [];

      for(const r of resultados) {
        if(this.buscaFiltrada.findIndex((obj) => obj.nomeLimpo == r.nomeLimpo) == -1)
          this.buscaFiltrada.push(r);
      }

      return this.buscaFiltrada.length > 0;

    },

    buscar: async function (event) {

      if(await this.buscaInterna(event.query))
        return;

      // let p = {idEmpresa: this.empresa.id,nome: event.query};
      // const res = await this.$api.post("/mongodb/produto",p);
      // if (res.data.success) {
      //   this.buscaFiltrada = res.data.data;
      // }
    },
    click(t) {
      this.$router.push({
        name: "busca",
        params: {
          text: t.value.nomeApresentacao,
        },
      });
    },
  },
  components: {
    CadastroCliente,
  },
};
</script>

<style lang="scss" scoped>
.layout-topbar {
  background-color: var(--primaryBackgroundColor, $primaryBackgroundColor);
  z-index: 999;
}

.layout-top-button {
  background-color: $alternativeBackgroundColor;
  color: var(--secondaryColor, $secondaryColor);
  line-height: 0.5;
}

.pi-search {
  top: 0.95rem;
  left: 0.9rem;
  color: var(--secondaryColor, $secondaryColor);
}

.layout-topbar > .grid {
  max-width: 1000px;
  margin: auto;
}

.p-button:enabled:active, .p-button:enabled:hover {
  background: var(--primaryBackgroundColor, $primaryBackgroundColor);
  color: #ffffff;
  border-color: #ffffff;
}

</style>