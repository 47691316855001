<template>
  <div class="produto-completo-component overflow-hidden ml-2 mr-2">
    <div class="produto-completo-detalhes flex">
      <div class="col-fixed" style="width: 65px" v-if="icone">
        <Image :src="icone" alt="Logo" class="
            produto-completo-profile-icon
            overflow-hidden
            border-circle
            block
          " style="--aspect-ratio: 1/1" imageClass="max-w-full h-auto block" />
      </div>
      <div class="col-12">
        <div class="
            produto-completo-titulo
            mb-1
            mt-0
            text-xl
            font-bold
            white-space-nowrap
            overflow-hidden
            text-overflow-ellipsis
          ">
          {{ nome }}
        </div>
        <div class="produto-completo-descricao">
          <div
            class="
              
              pb-0
              font-light
              white-space-nowrap
              overflow-hidden
              text-overflow-ellipsis
            "
          >
            {{ descricao }}
          </div>
        </div>
      </div>
    </div>

    <div class="produto-completo-acoes grid">

      <div class="
            col-6
            text-xl
            white-space-nowrap
            overflow-hidden
            text-overflow-ellipsis
            ml-2
            mt-2
          ">
        Numero: {{ numero }}
      </div>
      <div class="produto-completo-descricao">
        <div class="
             text-xl
            white-space-nowrap
            text-overflow-ellipsis
              col-6
              mt-2
            ">
          Status: {{ status }}

        </div>
      </div>
      <div class="
          produto-completo-preco
          text-xl
          font-bold
          text-right
          col-6
          mb-2
          ml-2
        " style="display: flex">
        {{ $utils.formatCurrency(value.valorTotal) }}
      </div>

      <Button label="Acompanhar" icon="pi pi-check-square" class="p-button-text mb-2" @click="addItem" />
    </div>
  </div>

  <Dialog :position="$utils.isMobileCheck() ? 'bottom' : 'center'" v-model:visible="displayAdicionarCarrinho"
    :style="{ width: '410px', 'max-width': '100%' }" :modal="true" :closable="true">
    <template #header>
      <h5 class="m-0 text-xl font-medium">
        <i class="pi pi-check-square" style="font-size: 1.3rem"></i> Acompanhar
        Pedido
      </h5>
    </template>
    <AcompanharPedido :value="value" />
  </Dialog>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  emits: ["add", "like", "share"],
  name: "ProdutoCompleto",
  data() {
    return {
      displayAdicionarCarrinho: false,
      w: 0,
    };
  },
  mounted() {
    this.w = this.$el.parentElement.offsetWidth;
  },
  computed: {
    nome() {
      if (
        this.value &&
        typeof this.value.loja.nome != "undefined" &&
        this.value.loja.nome
      ) {
        return this.value.loja.nome;
      }
      return "";
    },
    descricao() {
      if (
        this.value &&
        typeof this.value.listaProdutos != "undefined" &&
        Object.values(this.value.listaProdutos).length > 0
      ) {
        let descricao = [];
        for (let d of Object.values(this.value.listaProdutos)) {
          descricao.push(d.nome);
        }
        return descricao.join(",");
      }
      return "";
    },
    imagens() {
      if (
        this.value &&
        typeof this.value.foto != "undefined" &&
        this.value.foto
      ) {
        return this.value.foto;
      }
      return "";
    },
    icone() {
      if (
        this.value &&
        typeof this.value.loja.imagem != "undefined" &&
        this.value.loja.imagem
      ) {
        return this.value.loja.imagem;
      }
      return "";
    },
    status() {
      if (this.value && typeof this.value.status != "undefined") {
        return this.value.status;
      }
      return "";
    },
    numero() {
      if (this.value && typeof this.value.numero != "undefined") {
        return this.value.numero;
      }
      return "";
    },
  },
  methods: {
    addItem() {
      this.displayAdicionarCarrinho = true;
      this.$emit("add", this.value);
    },
    likeItem() {
      this.$emit("like", this.value);
    },
    shareItem() {
      this.$emit("share", this.value);
    },
  },
};
</script>

<style lang="scss">
.produto-completo-acoes,
.produto-completo-detalhes {
  background-color: $alternativeBackgroundColor;
  border-radius: 0.3rem;
}

.produto-completo-descricao>.col {
  max-width: calc(100% - 140px);
}

.produto-completo-detalhes>.col {
  max-width: calc(100% - 65px - 130px);
}
</style>

<style lang="scss" scoped>
.p-button.p-button-text {
  color: $alternativeColor;
}

.p-button.p-button-text:enabled:hover {
  color: $primaryColor;
  background: none;
}

.p-button {
  @media screen and (max-width: 500px) {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
}
</style>