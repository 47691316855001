import { reactive } from 'vue';
import { getPathOfObject, validDate, validObject, validString } from '@/support/utils';
import Cookie from 'js-cookie';

export default class Auth {

    #api;
    #rt;
    #usuario = reactive({});
    #empresa = reactive({});
    #loja = reactive({});
    #expire;

    #idEmpresa = "";
    #idLoja = "";

    constructor(api, rt = null) {

        api = validObject(api, null, false);

        if(api == null)
            throw "[AUTH] API Inválida!";

        this.#rt = (rt)?rt:null;

        this.#api = api;

        this.clear();

        // #region INICIALIZAÇÃO VIA URL //

        const gToken = Auth.getToken();

        const parametros = window.location.pathname.substring(1).split("/").filter((el) => el);
        let idLoja = "";
        if(parametros.length > 0) {
            idLoja = parametros[0] ?? "";
        }

        if(idLoja == "") {
            idLoja = Cookie.get('params-id') ?? "";
        }

        if(idLoja == "") {
            idLoja = gToken.idLoja;
        }
        
        api.post("/ecommerce/sessao", {
            'dominio' : window.location.hostname,
            'loja' : idLoja,
        }).then((res) => {

            if(res.data.success) {

                this.#loja = Object.assign(this._clear(this.#loja), res.data.data.loja);
                this.#idLoja = res.data.data.loja.id;

                this.#empresa = Object.assign(this._clear(this.#empresa), res.data.data.empresa);
                this.#idEmpresa = res.data.data.empresa.id;

                Cookie.set('params-id', "", { secure: window.location.hostname != "localhost", expires: 365 });

                window.localStorage.setItem("VIPECOMMERCE/LOGIN", JSON.stringify({
                    token: gToken.token,
                    expire: gToken.expire,
                    empresa: this.#idEmpresa,
                    loja: this.#idLoja
                }));

                if(parametros.length > 0) {
                    history.pushState("", document.title, "/#/");
                }

            } else {

                window.location.href = '/#/erro';

            }

        });

        // #endregion INICIALIZAÇÃO VIA URL //

    }
    
    _clear(obj) {
        for(const key in obj) {
            delete obj[key];
        }
        return obj;
    }

    get usuario() {
        return this.#usuario;
    }

    get empresa() {
        return this.#empresa;
    }

    get loja() {
        return this.#loja;
    }

    get expire() {
        return this.#expire;
    }

    existsEmpresa() {
        return (typeof this.#empresa.id == "string" && this.#empresa.id != "");
    }

    existsLoja() {
        return (typeof this.#loja.id == "string" && this.#loja.id != "");
    }

    existsUsuario() {
        return (typeof this.#usuario.id == "string" && this.#usuario.id != "");
    }

    isLogged() {
        return this.existsUsuario();
    }

    clear() {
        this._clear(this.#usuario);
        this._clear(this.#empresa);
        this._clear(this.#loja);
    }

    async loadUsuario(id = "") {

        if(typeof id != "string" || id.trim() == "") {
            if(typeof this.#usuario.id == "string") {
                id = this.#usuario.id;
            }
        }

        if(id == "") {
            const message = "O ID do Usuário é inválido!";
            console.error("[AUTH] " + message);
            return {
                success: false,
                message: message
            }
        }

        try {

            const res = await this.#api.get("/usuarios/" + id);
            if (res?.data.success) {
                
                this.#usuario = Object.assign(this._clear(this.#usuario), res.data.data);

                return {
                    success: true,
                    message: "Informações do Usuário foram carregadas com sucesso!"
                }

            } else {

                const message = "O Usuário não existe no banco de dados! Contate o Suporte.";
                console.error("[AUTH] " + message);
                return {
                    success: false,
                    message: message
                }

            }

        } catch (e) {

            const message = "Erro ao carregar as informações do Usuário via API!";
            console.error("[AUTH] " + message);

            return {
                success: false,
                message: message
            }

        }

    }

    async loadEmpresa(id = "") {

        if(typeof id != "string" || id.trim() == "") {
            if(typeof this.#empresa.id == "string") {
                id = this.#empresa.id;
            } else if(typeof this.#idEmpresa == "string") {
                id = this.#idEmpresa;
            }
        }

        if(id == "") {
            const message = "O ID da Empresa é inválido!";
            console.error("[AUTH] " + message);
            return {
                success: false,
                message: message
            }
        }

        try {

            const res = await this.#api.get("/empresas/" + id);
            if (res?.data.success) {
                
                this.#empresa = Object.assign(this._clear(this.#empresa), res.data.data);
                this.#idEmpresa = id;

                return {
                    success: true,
                    message: "Informações da Empresa foram carregadas com sucesso!"
                }

            } else {

                const message = "A Empresa não existe no banco de dados! Contate o Suporte.";
                console.error("[AUTH] " + message);
                return {
                    success: false,
                    message: message
                }

            }

        } catch (e) {

            const message = "Erro ao carregar as informações da Empresa via API!";
            console.error("[AUTH] " + message);

            return {
                success: false,
                message: message
            }

        }

    }

    async loadLoja(id = "") {

        if(typeof id != "string" || id.trim() == "") {
            if(typeof this.#loja.id == "string") {
                id = this.#loja.id;
            } else if(typeof this.#idLoja == "string") {
                id = this.#idLoja;
            }
        }

        if(id == "") {
            const message = "O ID da Loja é inválido!";
            console.error("[AUTH] " + message);
            return {
                success: false,
                message: message
            }
        }

        try {

            const res = await this.#api.get("/lojas/" + id);
            if (res?.data.success) {
                
                this.#loja = Object.assign(this._clear(this.#loja), res.data.data);
                this.#idLoja = id;

                return {
                    success: true,
                    message: "Informações da Loja foram carregadas com sucesso!"
                }

            } else {

                const message = "A Loja não existe no banco de dados! Contate o Suporte.";
                console.error("[AUTH] " + message);
                return {
                    success: false,
                    message: message
                }

            }

        } catch (e) {

            const message = "Erro ao carregar as informações da Loja via API!";
            console.error("[AUTH] " + message);

            return {
                success: false,
                message: message
            }

        }

    }

    static getToken() {

        let data = window.localStorage.getItem("VIPECOMMERCE/LOGIN");
        if(typeof data == "string" && data != "") {
            data = JSON.parse(data);
        } else {
            return {
                success: false,
                message: "Sessão não identificada!",
                token: "",
                idEmpresa: "",
                idLoja: ""
            };
        }

        const expire = validDate(data?.expire);
        const date = new Date();
        if(!expire || expire >= date) {

            const token = data?.token ?? "";

            if(!token) {
                
                window.localStorage.setItem("VIPECOMMERCE/LOGIN", JSON.stringify({
                    token: "",
                    expire: null,
                    empresa: data?.empresa ?? "",
                    loja: data?.loja ?? ""
                }));        

                return {
                    success: false,
                    message: "Sessão não identificada!",
                    token: "",
                    idEmpresa: data?.empresa ?? "",
                    idLoja: data?.loja ?? ""
                };
            }

            return {
                success: true,
                token: token,
                idEmpresa: data?.empresa ?? "",
                idLoja: data?.loja ?? ""
            };
        }

        window.localStorage.setItem("VIPECOMMERCE/LOGIN", JSON.stringify({
            token: "",
            expire: null,
            empresa: data?.empresa ?? "",
            loja: data?.loja ?? ""
        }));

        return {
            success: false,
            message: "Token Expirado!",
            token: "",
            idEmpresa: data?.empresa ?? "",
            idLoja: data?.loja ?? ""
        };

    }

    /// API ///

    async login(email, senha) {

        const gToken = Auth.getToken();

        try {

            const res = await this.#api.post("/auth/login", {
                "email": email,
                "senha": senha
            });
            
            this.#expire = validDate(res?.data.data.expire);
            this.#usuario = Object.assign(this._clear(this.#usuario), res?.data.data);

            window.localStorage.setItem("VIPECOMMERCE/LOGIN", JSON.stringify({
                token: res?.data.data.token ?? "",
                expire: this.#expire,
                empresa: gToken.idEmpresa,
                loja: gToken.idLoja,
            }));

            this.#rt?.auth({
                token: res?.data.data.token,
                idEmpresa: gToken.idEmpresa,
                idLoja: gToken.idLoja
            })

            return {
                success: true,
                message: "Usuário autenticado com Sucesso!",
                data: res?.data.data
            }

        } catch (e) {

            const message = validString(getPathOfObject(e, "response.data.message"), "Erro ao autenticar Usuário via API!", true);
            console.error("[AUTH] " + message, e);

            return {
                success: false,
                message: message
            }

        }

    }

    async logout(force = false) {

        const gToken = Auth.getToken();

        try {

            if(!gToken.success) {
                return {
                    success: true,
                    message: "Usuário desconectado com Sucesso!"
                };
            }

            if(force || (await this.check()).success) {
                await this.#api.post("/auth/logout");
            }

            window.localStorage.setItem("VIPECOMMERCE/LOGIN", JSON.stringify({
                token: "",
                expire: null,
                empresa: gToken.idEmpresa,
                loja: gToken.idLoja,
            }));
    
            this.#expire = undefined;

            this._clear(this.#usuario);

            this.#rt?.logout();

            return {
                success: true,
                message: "Usuário desconectado com Sucesso!"
            }

        } catch (e) {

            window.localStorage.setItem("VIPECOMMERCE/LOGIN", JSON.stringify({
                token: "",
                expire: null,
                empresa: gToken.idEmpresa,
                loja: gToken.idLoja,
            }));
    
            this.#expire = undefined;

            this._clear(this.#usuario);

            const message = validString(getPathOfObject(e, "response.data.message"), "Erro ao desconectar Usuário!", true);

            if(e?.status != 401) {
                console.error("[AUTH] " + message, e);
            }

            return {
                success: false,
                message: message
            }

        }

    }

    async check(hasAnonymous = false) {

        const gToken = Auth.getToken();

        try {

            if(!gToken.success)
                throw gToken.message;

            const res = await this.#api.get("/auth/check");
            
            this.#expire = validDate(res?.data.data.expire);
            this.#usuario = Object.assign(this._clear(this.#usuario), res?.data.data);
            this.#loja = Object.assign(this._clear(this.#loja), res?.data.data.loja);
            
            window.localStorage.setItem("VIPECOMMERCE/LOGIN", JSON.stringify({
                token: gToken.token,
                expire: this.#expire,
                empresa: gToken.idEmpresa,
                loja: gToken.idLoja
            }));

            this.#rt?.auth({
                token: gToken.token,
                idEmpresa: gToken.idEmpresa,
                idLoja: gToken.idLoja
            })

            return {
                success: true,
                message: "Autenticação do Usuário verificada com Sucesso!"
            }

        } catch (e) {

            const message = validString(getPathOfObject(e, "response.data.message"), "Erro ao verificar a Autenticação do Usuário!", true);

            if(!hasAnonymous) {
                console.error("[AUTH] " + message, e);
            }

            this.logout(true);

            return {
                success: false,
                message: message
            }

        }

    }

}
